/** Loading component**/

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lds-ring {
  @apply inline-block relative w-[80px] h-[80px];
}
.lds-ring div {
  @apply box-border block absolute w-[64px] h-[64px] m-[8px] rounded-full;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

/** Layout **/

.app-layout {
  @apply h-screen w-screen overflow-hidden;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas: "header" "content" "footer";
}
.app-layout main.content {
  @apply h-full w-full overflow-x-hidden overflow-y-auto z-30 flex justify-center items-center;
  grid-area: content;
}
.app-layout header.header {
  @apply bg-primary flex flex-row justify-between items-center px-4 z-50;
  grid-area: header;
}
.app-layout header.header .logo {
  @apply bg-logoHeader h-full w-logoHeader bg-no-repeat bg-contain bg-center;
}
.app-layout footer.footer {
  @apply bg-zinc-900 text-white flex justify-between items-center px-4 z-0;
  grid-area: footer;
}
.app-layout footer.footer p {
  @apply text-xs;
}
.app-layout footer.footer .logo {
  @apply bg-logoFooter h-full w-logoFooter bg-no-repeat bg-contain bg-center;
}

/*********** Steps ***********/

/** Home **/
.home,
.finished {
  @apply flex flex-col justify-center items-center h-full w-full bg-white p-4 gap-6;
}
.home h1,
.finished h1 {
  @apply text-2xl;
}
.home p,
.finished p {
  @apply text-base text-center;
}
.home p span {
  @apply text-primary;
}
.error p span {
  @apply text-primary;
}

/** Process Finished **/

.finished p span.success {
  @apply text-green-500;
}
.finished p span.warning {
  @apply text-amber-500;
}
.loading-page {
  @apply flex justify-center items-center h-screen w-screen bg-primary;
}
.front-id div button {
  @apply bg-zinc-500 py-2 px-2 gap-2 leading-3 z-50 !important;
}
.front-id div button img {
  @apply invert grayscale;
}

/** Reset Permissions **/

.reset-permissions {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
ul {
  padding-inline-start: 0;
}
.reset-permissions li {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
}
.reset-permissions .arrow-up {
  position: absolute;
  right: 0px;
  top: -50px;
}
.reset-permissions .arrow-down {
  margin-left: 30px;
  margin-top: 15px;
}
.reset-permissions .three-dots {
  margin-left: 40px;
}
.reset-permissions span.blue {
  color: #00b2fd;
}
.reset-permissions span.number {
  width: 37px;
  height: 37px;
  background-color: #20263d;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-right: 10px;
}
.reset-permissions .button-container {
  display: flex;
  justify-content: center;
}

/** General **/

.front-id,
.back-id,
.selfie,
.reset-permissions,
.front-id div,
.back-id div,
.selfie div,
.reset-permissions div {
  /*@apply z-50 !important; */
}
.front-id div,
.back-id div,
.selfie div,
.facematch div div,
.reset-permissions div {
  @apply flex flex-col items-center /*justify-center  pt-2*/;
}
.facematch > div > div > div:last-child {
  @apply flex flex-row gap-1;
}
.button-container button {
  width: 215px;
  height: 62px;
  border-radius: 31px;
  box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.15);
  background-color: #3cb0f7;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  color: #fff;
  border: none;
}

/** Incode styles */

.IncodeTutorialButton,
.IncodeTutorialButtonFront,
.IncodeTutorialButtonBack,
.IncodeAreYouSureModalContinueProcessButton,
.IncodeAreYouSureModalDisconnectButton,
.IncodeDisconnectButtonQueue,
.IncodeExecutiveReadyConnectButton,
.IncodeExecutiveReadyDisconnectButton,
.IncodeRetakeContinueContainer,
.IncodeTutorialButton,
.IncodeTutorialButtonSelfie,
.front-id div button.IncodeTutorialButton.IncodeTutorialButtonSelfie,
.home button,
.finished button,
.front-id div button.IncodeRetakeContinueContainer,
.facematch div div div div button,
.error button {
  @apply bg-primary text-white px-6 py-3 font-bold rounded-md flex gap-2 items-center hover:opacity-80 min-w-0 z-50 h-auto leading-6 !important;
}
.front-id div button.IncodeRetakeButton {
  @apply bg-zinc-400 px-6 py-3 font-bold rounded-md flex gap-2 items-center hover:opacity-80 min-w-0 z-50 h-auto leading-6 !important;
  --tw-bg-opacity: 0 !important;
}
.front-id div button.IncodeRetakeButton img {
  --tw-invert: invert(0%);
}
.front-id .IncodeTutorialButton.IncodeTutorialButtonSelfie{
  @apply mb-12 !important;
}

#canvasFrame {
  display: none !important;
}

/** Components **/

.id-data {
  @apply border rounded-md p-2 w-full;
}
.id-data .id-bg {
  @apply bg-blue-200 bg-opacity-10 p-2;
}
.id-data .id-header {
  @apply flex gap-2 pb-1;
}
.id-data .id-header .id-logo {
  @apply w-8 h-8 bg-gray-200 border-2 border-white;
}
.id-data .id-header .id-info {
  @apply flex flex-col;
}
.id-data .id-header .id-info .id-country {
  @apply w-32 h-3 bg-gray-200 border-2 border-white;
}
.id-data .id-header .id-info .id-type {
  @apply w-28 h-3 bg-gray-200 border-2 border-white;
}
.id-data .id-content {
  @apply flex gap-2 pb-2;
}
.id-data .id-content .id-picture {
  @apply w-24 h-28 bg-gray-200 border-2 border-white;
}
.id-data .id-content .row {
  @apply flex gap-4 pb-1;
}
.id-data .id-label {
  @apply flex flex-col;
}
.id-data .id-label span.data {
  @apply text-sm;
}
.id-data .id-label span.title {
  @apply text-xs font-bold;
}
.id-data .id-footer {
  @apply flex justify-between;
}
.id-data .id-footer .id-barcode {
  @apply w-44 h-8 bg-gray-200 border-2 border-white;
}
.error button {
  @apply border shadow-md mt-4;
}

.whatsapp-help {
  @apply fixed bottom-2 left-2 z-50;
}
.whatsapp-help .wrapper {
  @apply bg-secondary p-2 rounded-md relative;
}
.whatsapp-help .wrapper a {
  @apply flex flex-row gap-2 items-center text-white;
}
.whatsapp-help .wrapper a p {
  @apply text-xs;
}
.whatsapp-help .wrapper a img {
  @apply w-8 h-8;
}
.whatsapp-help .wrapper .close {
  @apply absolute -top-2 -right-2 w-5 h-5 bg-white border-secondary border rounded-full flex justify-center items-center cursor-pointer text-sm ;
}
.whatsapp-help .wrapper .close span {
  @apply pb-[3px]
}