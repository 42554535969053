.IncodeRetakeContainer {
    margin-top: 5px;
}

.IncodeRetakeButtonsContainer {
    height: '40%'
}

li {
    list-style: disc inside;
}

.IncodeRetakeButton {
    /* @apply bg-zinc-400 text-primary px-6 py-3 font-bold rounded-md flex gap-2 items-center hover:opacity-80 min-w-0 z-50 h-auto leading-6 !important; */
    background-color: 'white'
}